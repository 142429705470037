import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminOtpComponent } from './admin-otp.component';
import { ThemeModule } from '../../@theme/theme.module';
@NgModule({
  declarations: [AdminOtpComponent],
  imports: [
    CommonModule,
    ThemeModule
  ]
})
export class AdminOtpModule {



 }
